import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputComponent } from './input.component';
import { AngularMaterialModule } from '../../../shared/modules/angular-material/angular-material.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, AngularMaterialModule, ReactiveFormsModule],
  declarations: [InputComponent],
  exports: [InputComponent],
})
export class SftInputModule { }
