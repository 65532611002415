import { Component, ViewEncapsulation, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { SftInputModule } from './input.module';
import { MatInputModule } from '@angular/material/input';
// import { NgxTippyModule } from 'ngx-tippy-wrapper';
// import { ButtonVariant } from './types';

@Component({
  selector: 'sft-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss', '../../../themes/dark.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent implements ControlValueAccessor{
  @Input() label: string = 'Label';

  @Input() readonly: boolean = false;

  // @Input() type: string;

  @Input() placeholder: string = 'Enter here...';

  @Input() hint?: string = '';

  @Input() disable: boolean = false;

  @Input() required: boolean = false;

  @Input() infoIcon: boolean = false;

  @Input() infoTitle: string = '';

  @Input() infoDetail: string = '';

  @Input() value: string = '';

  @Input() class: string = '';

  // errorState: boolean;

  @Input() matAutocomplete: any;

  @Input() autocompleteOptions: any[] = [];
  @Output() autocompleteSelected = new EventEmitter<any>();

  handleAutocompleteSelection(option: any): void {
    this.innerValue = option;
    this.onChange(this.innerValue);
    this.autocompleteSelected.emit(option);
  }

  // custom form control
  customFormControl: FormControl = new FormControl('', Validators.required);

  private innerValue: any = '';

  // Function to propagate changes inside the component
  private onChange: (value: any) => void = () => {};

  // Function to propagate touch event
  private onTouched: () => void = () => {};

  // Write value to the internal model
  writeValue(value: any): void {
    this.innerValue = value;
    this.customFormControl.setValue(value);
  }

  // Register a callback to propagate changes
  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  // Register a callback to propagate touch events
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  // Set the disabled state and propagate the change
  setDisabledState(isDisabled: boolean): void {
    // implement this if you need to handle disabled state
  }

  // Handle input changes and propagate the change
  handleChange(event: any): void {
    this.innerValue = event.target.value;
    this.onChange(this.innerValue);
  }
}
