<div class="input-wrapper">
  <div class="d-flex align-center mb-1">
    <p class="mat-body-1 text  mb-0  input-label">{{ label }} <span *ngIf="required" class="required-star">*</span> </p>
    <mat-icon *ngIf="infoIcon"
      class="material-symbols-outlined pointer ml-3 help-icon text-text text-primary">help</mat-icon>
    <ng-template #helpinfotooltip let-name>
      <div class="help-info-tooltip">
        <div class="heading-with-background">{{infoTitle}}</div>
        <div class="details">
          {{infoDetail}}
        </div>
      </div>
    </ng-template>
  </div>
  <mat-form-field appearance="outline" subscriptSizing="dynamic" [class]="'border-2' + ' ' + class">
    <mat-hint *ngIf="hint" align="start"><strong>{{ hint }}</strong>
    </mat-hint>
    <input [required]="required" matInput [placeholder]="placeholder" [readonly]="readonly" [disabled]="disable"
      [value]="value" [formControl]="customFormControl" (input)="handleChange($event)" />
  </mat-form-field>
</div>